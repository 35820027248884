<template>
    <div class="modal-mask"
         ref="modalContainer"
         @click.self.prevent="allowClosingOnBackdropClick && dismiss()">
        <div class="modal-wrapper"
             :class="[large && 'modal-lg']"
             tabindex="-1"
             role="dialog"
             aria-labelledby="">
            <div class="modal-content" :class="[props.asideImage && 'with-side-image']">
                <template v-if="props.asideImage">
                    <aside />
                </template>
                <div class="modal-header">
                    <div class="header-content">
                        <slot name="header" />
                    </div>
                    <button v-if="isDismissible"
                            @click.prevent="dismiss"
                            type="button"
                            class="close"
                            aria-hidden="true">
                        <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                            <path d="m16 1.1-1.1-1.1-6.9 6.9-6.9-6.9-1.1 1.1 6.9 6.9-6.9 6.9 1.1 1.1 6.9-6.9 6.9 6.9 1.1-1.1-6.9-6.9z" fill-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body" />
                </div>
                <template v-if="slots.footer">
                    <div class="modal-footer">
                        <slot name="footer" />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref, watch } from 'vue';

interface Props {
    active: boolean;
    large?: boolean;
    isDismissible?: boolean;
    allowClosingOnBackdropClick?: boolean;
    blockDismiss?: boolean;
    asideImage?: string;
}

const props = withDefaults(defineProps<Props>(), {
    active:                      false,
    large:                       false,
    isDismissible:               true,
    allowClosingOnBackdropClick: true,
});

const slots = defineSlots<{
    header: () => any,
    body: () => any,
    footer?: () => any,
}>();

const emit = defineEmits<{
    (e: 'modal:dismiss'): void;
}>();

const modalContainer = ref<HTMLDivElement>();

const modalRows = computed(() => {
    return slots.footer ? 3 : 2;
});

const firstRowLength = computed(() => {
    return props.asideImage ? 'auto' : '0';
});

const sideImage = computed(() => `url("${props.asideImage}")`);

watch(() => props.active, () => {
    if (props.active) {
        lockScroll();
    } else {
        unlockScroll();
    }
});

defineExpose({
    dismiss,
});

onUnmounted(() => {
    // In case modal is removed from DOM without user explicitly closing the modal
    // Without this, if the user clicks the browser "back" button, or does something else to cause the modal to close
    // without a page reload happening, then the page remains un-scrollable as if the modal were still open.
    if (props.active) {
        unlockScroll();
    }
});

function lockScroll(): void {
    document.documentElement.style.overflow = 'hidden';
    document.body.classList.add('modal-open');

    if (modalContainer.value) {
        const container = modalContainer.value;
        container.style.display = 'flex';
        setTimeout(() => {
            container.classList.add('in');
        }, 50);
    }
}

function unlockScroll(): void {
    if (modalContainer.value) {
        const container = modalContainer.value;

        container.classList.remove('in');
    }

    setTimeout(() => {
        document.body.classList.remove('modal-open');
        document.documentElement.style.removeProperty('overflow');

        if (modalContainer.value) {
            modalContainer.value.style.removeProperty('display');
        }
    }, 300);
}

function dismiss(): void {
    if (!props.isDismissible || props.blockDismiss) {
        return;
    }

    emit('modal:dismiss');
}
</script>

<style scoped lang="scss">
@import "@/global/scss/viewport.scss";
@import "@/global/css/redesign/scss/general/variables.scss";

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: none;
    flex-direction: row;
    transition: opacity 0.15s linear;
    opacity: 0;
    overflow-y: scroll;
    justify-content: center;
    align-items: center;

    &.in {
        opacity: 1;

        .modal-wrapper {
            transform: translate(0, 0);
        }
    }

    .modal-wrapper {
        display: flex;
        justify-content: center;
        margin: auto;
        padding: 0;
        transition: transform 0.3s ease-out;
        transform: translate(0, -25px);

        &.modal-lg {
            .modal-content {
                .modal-header {
                    font-size: 24px;
                    font-weight: 400;
                }

                .close {
                    font-size: 32px;
                    font-weight: 100;
                }
            }

            @media (min-width: $viewport-md) {
                width: 944px;
            }
        }
        @media (min-width: $viewport-xs) {
            width: 480px;
            margin: 0 auto;
        }
        @media (max-width: $viewport-xs-max) {
            padding: $size-3 $size-1;
        }
        @media (min-width: 700px) {
            width: 540px;
        }
        @media (min-width: $viewport-sm) {
            max-height: 80vh;
        }
        @media (min-width: $viewport-md) {
            width: 600px;
        }

        .modal-content {
            display: grid;
            grid-template-rows: repeat(v-bind(modalRows), auto);
            grid-template-columns: v-bind(firstRowLength) auto;
            grid-auto-flow: row;
            border-radius: $size-0;
            box-shadow: 0 5px 15px rgb(0  0  0 / 50%);
            padding: $size-4;
            width: 100%;
            @media (max-width: $viewport-xs-max) {
                padding: $size-4 $size-2;
            }

            aside {
                grid-column: 1;
                grid-row: 1 / -1;
                background-image: v-bind(sideImage);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                width: 160px;
                height: 100%;
            }

            .modal-header, .modal-footer, .modal-body {
                display: flex;
                grid-column: 2;
                position: relative;
                padding: 0 $size-2 !important;
            }

            .modal-body {
                gap: $size-2;
                overflow-y: auto;
            }
            .modal-body,
            .modal-footer {
                flex-direction: column;
                margin: 0;
            }
            .modal-footer {
                margin-top: $size-1;
                row-gap: $size-1;
            }
            .modal-header {
                justify-content: space-between;
                align-items: flex-start;
                font-size: 20px;
                line-height: 28px;
                height: 100%;
                .header-content {
                    flex-grow: 1;
                }
            }

            &.with-side-image {
                padding: 0;

                .modal-header {
                    padding-top: $size-3 !important;
                }
                div:last-child {
                    padding-bottom: $size-3 !important;
                }
                .modal-header, .modal-footer, .modal-body {
                    padding-right: $size-2;
                }
            }

            button.close {
                width: $size-4;
                height: $size-4;
                margin: 0 auto 0 $size-1;
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 14px;
                    height: 14px;
                    @media (max-width: $viewport-xs-max) {
                        margin-bottom: 12px;
                    }
                }
            }
            @media (max-width: $viewport-xs-max) {
                width: 100%;
                min-height: 100%;
                max-height: 92vh;
            }
        }
    }
}
</style>
